import Image from "next/image";
import {
  ChatIcon,
  TrendingUpIcon,
  LightningBoltIcon,
  ShareIcon,
  ScaleIcon,
  InboxIcon,
} from "@heroicons/react/outline";
import FrontpageGraphic from "../../public/frontpage_graphic.png";
import FrontpageGraphic2 from "../../public/frontpage_graphic2.png";

const transferFeatures = [
  {
    id: 1,
    name: "Workflows built for startups",
    description:
      "Symbola makes it easy to incorporate and handle legal requirements.",
    icon: TrendingUpIcon,
  },
  {
    id: 2,
    name: "Follow the best practices of experienced founders",
    description: "Establish your company the way VCs and investors prefer.",
    icon: ScaleIcon,
  },
  {
    id: 3,
    name: "The fastest incorporation tools yet",
    description: "Complete the incorporation workflow in 10 minutes.",
    icon: LightningBoltIcon,
  },
];
const communicationFeatures = [
  {
    id: 1,
    name: "Live support included",
    description: "Talk to our support team by phone, email, or chat.",
    icon: ChatIcon,
  },
  {
    id: 2,
    name: "Easy for teams",
    description:
      "Our seamless signature process makes it easy for co-founders to incorporate and grow together.",
    icon: ShareIcon,
  },
];

const FeatureSection: React.FC = ({}) => {
  return (
    <>
      <div className="pt-16 bg-gray-50 overflow-hidden lg:pt-24 lg:pb-8">
        <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
          <div className="relative">
            <h2 className="text-center text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              The best legal tools for startups
            </h2>
            <p className="mt-4 max-w-3xl mx-auto text-center text-xl text-gray-500">
              Form a Delaware C-Corp with our guided workflows. Issue stock to
              the founders with the push of a button. Make and file your Section
              83(b) Election.
            </p>
          </div>

          <div className="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
            <div className="relative">
              <h3 className="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">
                Turnkey formation
              </h3>
              <p className="mt-3 text-lg text-gray-500">
                Make informed decisions as you incorporate with our tools.
                Follow the best practices for founders who plan to grow and
                fundraise. Registered Agent and Employer Identification Number
                included.
              </p>

              <dl className="mt-10 space-y-10">
                {transferFeatures.map((item) => (
                  <div key={item.id} className="relative">
                    <dt>
                      <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-blue-500 text-white">
                        <item.icon className="h-6 w-6" aria-hidden="true" />
                      </div>
                      <p className="ml-16 text-lg leading-6 font-medium text-gray-900">
                        {item.name}
                      </p>
                    </dt>
                    <dd className="mt-2 ml-16 text-base text-gray-500">
                      {item.description}
                    </dd>
                  </div>
                ))}
              </dl>
            </div>

            <div className="mt-10 -mx-4 relative lg:mt-0" aria-hidden="true">
              <Image
                className="relative mx-auto"
                width={1238}
                height={752}
                src={FrontpageGraphic2}
                alt="Symbola Incorporation Workflow"
              />
            </div>
          </div>

          <div className="relative mt-12 sm:mt-16 lg:mt-24">
            <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
              <div className="lg:col-start-2">
                <h3 className="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">
                  How legal tools should work
                </h3>
                <p className="mt-3 text-lg text-gray-500">
                  Our software makes it easy to handle your startup&apos;s legal
                  requirements.
                </p>

                <dl className="mt-10 space-y-10">
                  {communicationFeatures.map((item) => (
                    <div key={item.id} className="relative">
                      <dt>
                        <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-blue-500 text-white">
                          <item.icon className="h-6 w-6" aria-hidden="true" />
                        </div>
                        <p className="ml-16 text-lg leading-6 font-medium text-gray-900">
                          {item.name}
                        </p>
                      </dt>
                      <dd className="mt-2 ml-16 text-base text-gray-500">
                        {item.description}
                      </dd>
                    </div>
                  ))}
                </dl>
              </div>

              <div className="mt-10 -mx-4 relative lg:mt-0 lg:col-start-1">
                <Image
                  className="relative mx-auto"
                  width={490}
                  height={316}
                  src={FrontpageGraphic}
                  alt="Symbola Founder Shares"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default FeatureSection;
