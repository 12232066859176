import { useState } from "react";
import Link from "next/link";
import Image from "next/image";
import { ChevronRightIcon } from "@heroicons/react/solid";
import axios from "axios";
import FeatureSection from "components/frontpage/features";
import LegalToolsSection from "components/frontpage/legalTools";
import CTASection from "components/frontpage/cta";
import Header from "components/frontpage/header";
import Footer from "components/frontpage/footer";
import FrontpageHero from "public/frontpage_hero.svg";
import { getSession } from "next-auth/react";
import { GetServerSideProps, GetServerSidePropsContext } from "next";

const FrontPage: React.FC = ({}) => {
  const privateBeta = process.env.NEXT_PUBLIC_PRIVATE_BETA === "true";
  const [value, setValue] = useState("");
  const [didSubmit, setDidSubmit] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const handleBetaSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await axios.post(`/api/slackPostMessage`, {
      messageString: `🙋 New private beta waitlist request from: ${value}.  Save the email in running list until we open up.`,
    });
    setDidSubmit(true);
    setValue("");
  };

  return (
    <div className="min-h-screen">
      <div className="relative overflow-hidden">
        <Header lightMode={false} />
        <main>
          {/* #ABOVE THE FOLD HERO */}
          <div className="pt-10 bg-gray-900 sm:pt-16 lg:pt-8 lg:pb-14 lg:overflow-hidden">
            <div className="mx-auto max-w-7xl lg:px-8">
              <div className="lg:grid lg:grid-cols-2 lg:gap-8">
                <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 sm:text-center lg:px-0 lg:text-left lg:flex lg:items-center">
                  <div className="lg:py-24">
                    {!privateBeta ? (
                      <Link href="/register">
                        <a className="inline-flex items-center text-white bg-black rounded-full p-1 pr-2 sm:text-base lg:text-sm xl:text-base hover:text-gray-200">
                          <span className="px-3 py-0.5 text-white text-xs font-semibold leading-5 uppercase tracking-wide bg-blue-500 rounded-full">
                            File free
                          </span>
                          <span className="hidden sm:block ml-4 text-sm">
                            100% free incorporation for startups.
                          </span>
                          <span className="sm:hidden ml-4 text-sm">
                            Free startup incorporation
                          </span>
                          <ChevronRightIcon
                            className="ml-2 w-5 h-5 text-gray-500"
                            aria-hidden="true"
                          />
                        </a>
                      </Link>
                    ) : (
                      <div className="inline-flex items-center text-white bg-black rounded-full p-1 pr-2 sm:text-base lg:text-sm xl:text-base hover:text-gray-200">
                        <span className="px-3 py-0.5 text-white text-xs font-semibold leading-5 uppercase tracking-wide bg-blue-500 rounded-full">
                          File free
                        </span>
                        <span className="hidden sm:block ml-4 text-sm">
                          100% free incorporation for startups.
                        </span>
                        <span className="sm:hidden ml-4 text-sm">
                          Free startup incorporation
                        </span>
                        <ChevronRightIcon
                          className="ml-2 w-5 h-5 text-gray-500"
                          aria-hidden="true"
                        />
                      </div>
                    )}
                    <h1 className="mt-4 text-4xl tracking-tight font-extrabold text-white sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
                      <span className="block">A better way to</span>
                      <span className="block text-blue-400">
                        incorporate your startup.
                      </span>
                    </h1>
                    <p className="mt-3 text-base text-gray-300 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                      Symbola is the complete legal toolkit for new startups.
                      Form your Delaware C-Corp in 10 minutes.
                    </p>
                    {!privateBeta ? (
                      <div className="mt-4 sm:mt-8">
                        <form
                          action="#"
                          className="sm:max-w-xl sm:mx-auto lg:mx-0"
                        >
                          <div className="sm:flex">
                            <div className="mt-3 sm:mt-0 ">
                              <Link href="/register" passHref>
                                <button className="block w-full py-3 px-4 rounded-md shadow bg-blue-500 text-white font-medium hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-300 focus:ring-offset-gray-900">
                                  Begin now
                                </button>
                              </Link>
                            </div>
                          </div>
                        </form>
                      </div>
                    ) : (
                      // Private beta conditional
                      <>
                        <form
                          onSubmit={handleBetaSubmit}
                          className="mt-12 sm:max-w-lg sm:w-full sm:flex"
                        >
                          <div className="min-w-0 flex-1">
                            <label htmlFor="hero-email" className="sr-only">
                              Email address
                            </label>
                            <input
                              id="hero-email"
                              type="email"
                              value={value}
                              onChange={handleChange}
                              className="block w-full border border-gray-300 rounded-md px-5 py-3 text-base text-gray-900 placeholder-gray-500 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                              placeholder="Enter your email"
                            />
                          </div>
                          <div className="mt-4 sm:mt-0 sm:ml-3">
                            <button
                              type="submit"
                              className="block w-full rounded-md border border-transparent px-5 py-3 bg-blue-500 text-base font-medium text-white shadow hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:px-10"
                            >
                              Get access
                            </button>
                          </div>
                        </form>
                        <div className="mt-2">
                          <div className="inline-flex items-center ">
                            {!didSubmit ? (
                              <div className="min-w-0 flex-1 py-1 text-sm text-gray-300 sm:py-3">
                                Request private beta access to incorporate for
                                free.
                              </div>
                            ) : (
                              <div className="min-w-0 flex-1 py-1 text-sm text-green-300 sm:py-3">
                                Thank you for signing up! We will be in touch
                                shortly.
                              </div>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className="mt-12 -mb-16 sm:-mb-48 lg:m-0 lg:relative">
                  <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0">
                    {/* Illustration taken from Lucid Illustrations: https://lucid.pixsellz.io/ */}
                    <Image
                      className="w-full lg:absolute lg:inset-y-0 lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                      src={FrontpageHero}
                      alt="Incorporate a Startup"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* CONTENT.. */}
          {/*NOTE: <LargeScreenshotSection /> is available in prior commits if I want to resurrect this new layout under issue #327*/}
          <FeatureSection />
          <LegalToolsSection privateBeta={privateBeta} />
          <CTASection
            privateBeta={privateBeta}
            handleBetaSubmit={handleBetaSubmit}
            didSubmit={didSubmit}
            value={value}
            handleChange={handleChange}
          />
          <Footer />
          <div></div>
        </main>
      </div>
    </div>
  );
};

// TODO:  I believe this could be the cause of a slowdown in page speed analysis.
// Intent was to have returning users go straight to their dashboard, but may be better to handle this in a call that does not
// does block rendering and optimize this home page to the experience of the new user.  See Issue #469

export const getServerSideProps: GetServerSideProps = async (
  context: GetServerSidePropsContext
) => {
  const session = await getSession(context);
  if (session && session.symbolaUser) {
    return {
      redirect: {
        permanent: false,
        destination: "/app",
      },
    };
  }

  return {
    props: {},
  };
};

export default FrontPage;
