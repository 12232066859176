import Link from "next/link";

const tools = [
  {
    name: "Incorporation Workflow",
    title:
      "Form a Deleware C-Corp, issue your bylaws, and obtain a registered agent and EIN using our self-guided startup formation workflow.",
    badge: "Free",
    href: "/tools/incorporation",
  },

  {
    name: "Founder Shares Workflow",
    title:
      "Issue stock to you and your co-founders by generating Common Stock Purchase Agreements that follow best practices.",
    badge: "Free",
    href: "/tools/founder-shares",
  },

  {
    name: "83(b) Election",
    title:
      "Make the right choice for how to handle the taxation of your common stock, including instructions on how to file with the IRS.",
    badge: "Free",
    href: "/tools/founder-shares",
  },

  {
    name: "Cap Table Management",
    title:
      "Understand ownership and control in your company.  Onboard instantly using your company profile.  Stay ready to fundraise and pass dilligence.",
    badge: "Coming Soon",
    href: "/tools/equity-management",
  },

  {
    name: "Employees & Contractors",
    title:
      "Hire your first employees, generate offer letters, and issue stock options.",
    badge: "Coming Soon",
    href: "/tools/equity-management",
  },
  {
    name: "Fundraising",
    title:
      "Raise capital seamlessly by generating documents, managing equity, and passing dilligence.",
    badge: "Coming Soon",
    href: "/tools/equity-management",
  },
];

interface LegalToolsProps {
  privateBeta: boolean;
}

const LegalTools: React.FC<LegalToolsProps> = ({ privateBeta }) => {
  return (
    <div id="tools" className="py-16 bg-gray-50 overflow-hidden lg:py-24">
      <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
        <div className="relative">
          <h2 className="text-center text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            Free for founders.
          </h2>
          <p className="mt-4 max-w-3xl mx-auto text-center text-xl text-gray-500">
            Our startup package is always free. We grow with you. No credit card
            required.
          </p>
        </div>
        <div className="mt-8">
          <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
            {tools.map((tool, idx) => (
              <li
                key={idx}
                className="col-span-1 group  bg-white  rounded-lg shadow hover:shadow-md flex flex-col justify-between"
              >
                {!privateBeta ? (
                  <Link href={tool.href} passHref>
                    <div className="group cursor-pointer w-full flex items-center py-4 px-6 space-x-6">
                      <div className="group">
                        <div className="flex-1 group mb-1">
                          {tool.badge === "Free" && (
                            <span className="flex-shrink-0 inline-block px-2 mb-1.5 text-green-800 text-xs font-medium bg-green-100 rounded-full">
                              Free
                            </span>
                          )}

                          {tool.badge === "Coming Soon" && (
                            <>
                              <span className="flex-shrink-0 inline-block px-2 mb-1.5 text-green-800 text-xs font-medium bg-green-100 rounded-full">
                                Free
                              </span>
                              <span className="ml-2 flex-shrink-0 inline-block px-2 mb-1.5 text-blue-800 text-xs font-medium bg-blue-100 rounded-full">
                                Coming Soon
                              </span>
                            </>
                          )}
                        </div>
                        <div className="group flex items-center space-x-3">
                          <h3 className="group text-gray-900 text-sm font-medium ">
                            {tool.name + " →"}
                          </h3>
                        </div>
                        <p className="mt-1 text-gray-500 text-sm ">
                          {tool.title}
                        </p>
                      </div>
                    </div>
                  </Link>
                ) : (
                  <div className="group w-full flex items-center py-4 px-6 space-x-6">
                    <div className="group">
                      <div className="flex-1 group mb-1">
                        {tool.badge === "Free" && (
                          <span className="flex-shrink-0 inline-block px-2 mb-1.5 text-green-800 text-xs font-medium bg-green-100 rounded-full">
                            Free
                          </span>
                        )}

                        {tool.badge === "Coming Soon" && (
                          <>
                            <span className="flex-shrink-0 inline-block px-2 mb-1.5 text-green-800 text-xs font-medium bg-green-100 rounded-full">
                              Free
                            </span>
                            <span className="ml-2 flex-shrink-0 inline-block px-2 mb-1.5 text-blue-800 text-xs font-medium bg-blue-100 rounded-full">
                              Coming Soon
                            </span>
                          </>
                        )}
                      </div>
                      <div className="group flex items-center space-x-3">
                        <h3 className="group text-gray-900 text-sm font-medium ">
                          {tool.name + " →"}
                        </h3>
                      </div>
                      <p className="mt-1 text-gray-500 text-sm ">
                        {tool.title}
                      </p>
                    </div>
                  </div>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};
export default LegalTools;
