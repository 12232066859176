import Link from "next/link";
import Image from "next/image";

interface CTASectionProps {
  privateBeta: boolean;
  value: string;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleBetaSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  didSubmit: boolean;
}

const CTASection: React.FC<CTASectionProps> = ({
  privateBeta,
  value,
  handleChange,
  handleBetaSubmit,
  didSubmit,
}) => {
  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <div className="bg-blue-700 rounded-lg shadow-xl overflow-hidden lg:grid lg:grid-cols-2 lg:gap-4">
          <div className="pt-10 pb-12 px-6 sm:pt-16 sm:px-16 lg:py-16 lg:pr-0 xl:py-20 xl:px-20">
            <div className="lg:self-center">
              <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
                <span className="block">Ready to incorporate?</span>
                <span className="block">
                  Form your startup for free, today.
                </span>
              </h2>
              {!privateBeta ? (
                <Link href="/register">
                  <a className="mt-8 bg-white border border-transparent rounded-md shadow px-5 py-3 inline-flex items-center text-base font-medium text-blue-600 hover:bg-blue-50">
                    Begin →
                  </a>
                </Link>
              ) : (
                // PRIVATE BETA CONDITIONAL
                <>
                  <form
                    onSubmit={handleBetaSubmit}
                    className="mt-12 sm:max-w-lg sm:w-full sm:flex"
                  >
                    <div className="min-w-0 flex-1">
                      <label htmlFor="hero-email" className="sr-only">
                        Email address
                      </label>
                      <input
                        id="hero-email"
                        type="email"
                        value={value}
                        onChange={handleChange}
                        className="block w-full border border-gray-300 rounded-md px-5 py-3 text-base text-gray-900 placeholder-gray-500 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                        placeholder="Enter your email"
                      />
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-3">
                      <button
                        type="submit"
                        className="block w-full rounded-md border border-transparent px-5 py-3 bg-white text-base font-medium text-blue-600 hover:bg-blue-50 shadow focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:px-10"
                      >
                        Get access
                      </button>
                    </div>
                  </form>
                  <div className="mt-2">
                    <div className="inline-flex items-center ">
                      {!didSubmit ? (
                        <div className="min-w-0 flex-1 py-1 text-sm text-gray-300 sm:py-3">
                          Request private beta access to incorporate for free.
                        </div>
                      ) : (
                        <div className="min-w-0 flex-1 py-1 text-sm text-green-300 sm:py-3">
                          Thank you for signing up! We will be in touch shortly.
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="-mt-6 aspect-w-5 aspect-h-3 md:aspect-w-2 md:aspect-h-1">
            <div className="transform translate-x-6 translate-y-6 object-cover object-left-top sm:translate-x-16 lg:translate-y-20">
              {" "}
              <Image
                className="rounded-md "
                src="/sidehang-screenshot-dashboard.jpg"
                height={768}
                width={1280}
                alt="Symbola dashboard screenshot"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CTASection;
